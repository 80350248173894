export const environment = {
  production: false,
  allowPasswordLogin: true,
  apiUrl: 'https://armsapidev.innovativeideas.com.br',
  aadRedirectUri: 'https://armsdev.innovativeideas.com.br/login',
  aadClientId: '3b5d9179-4820-4518-a175-a4ba0ca64dac',
  aadTenantId: 'ec230af7-d9c4-4e7d-bd31-075af4f3d29b',
  gtm: {
    containerId: 'GTM-MZKKRQT',
    auth: 'Y3ZXlnXf55Apv2pam6jBaA',
    preview: 'env-3',
    debug: true,
  },
  fromEmail: 'ir@absinv.com',
  fromName: 'ABS Investor Relations',
};
